<template>
  <div class="menuManage">
    <el-card class="box-card father-card"
             shadow="never">
      <!-- 按钮区域 -->
      <el-row class="btns">
        <el-button type="primary"
                   @click="addBtn"
                   v-if="btnList.includes(93)">添加</el-button>
        <el-button type="primary"
                   @click="eidtMenu()"
                   v-if="btnList.includes(100)">修改</el-button>
        <!-- <el-button type="primary">导出客户</el-button> -->
        <el-button type="primary"
                   @click="delMenu()"
                   v-if="btnList.includes(101)">删除</el-button>
      </el-row>
      <!-- 树状图区域 -->
      <el-row class="treeData">
        <el-table class="treeTable"
                  :data="data"
                  row-key="uuid"
                  border
                  :tree-props="options"
                  @current-change="handleCurrentChange"
                  :header-cell-style="rowClass"
                  highlight-current-row>
          <el-table-column prop="menuName"
                           label="节点名称"
                           align="center"></el-table-column>
          <el-table-column prop="menuUrl"
                           label="请求路径"
                           align="center"
                           min-width="200px"></el-table-column>
          <!-- <el-table-column prop="iconfont" label="图标" align="center"></el-table-column> -->
          <el-table-column prop="menuType"
                           label="节点类型"
                           align="center">
            <template slot-scope="scope">
              <span effect="dark"
                    v-if="scope.row.menuType==='0'">{{ '菜单' }}</span>
              <span type="warning"
                    effect="dark"
                    v-else>{{ '按钮' }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="status"
                           label="状态"
                           align="center">
            <template slot-scope="scope">
              <span effect="dark"
                    type="success"
                    v-if="scope.row.status==='1'">{{ '正常' }}</span>
              <span type="info"
                    effect="dark"
                    v-else>{{ '停用' }}</span>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <!--添加菜单对话框-->
      <el-dialog :visible.sync="addMenuDialogVisible"
                 @close="addMenuDialogVisibleClose"
                 class="add-dialog">
        <span slot="title"
              class="dialog-Title">添加菜单</span>
        <el-form :rules="addMenuRules"
                 ref="addMenuFormRef"
                 :model="addMenuForm"
                 inline>
          <el-row>
            <el-form-item label="上级菜单"
                          prop="topMenu">
              <el-popover placement="bottom-start"
                          trigger="click"
                          class="popover-main">
                <div class="treeList">
                  <el-tree ref="tree"
                           :expand-on-click-node="false"
                           highlight-current
                           :check-strictly="checkStrictly"
                           :data="data"
                           :props="defaultProps"
                           node-key="path"
                           class="permission-tree"
                           @node-click="handleNodeClick($event,'add')" />
                </div>
                <el-input slot="reference"
                          v-model="addMenuForm.parentIdName"
                          placeholder="请选择上级菜单"
                          readonly />
              </el-popover>
              <!-- <el-select v-model="addMenuForm.parentId" placeholder="请选择上级菜单" clearable filterable>
                <el-option
                  :label="item.menuName"
                  :value="item.uuid"
                  v-for="(item, index) in allData"
                  :key="index"
                ></el-option>
              </el-select> -->
            </el-form-item>
            <el-form-item label="菜单名称"
                          prop="menuName">
              <el-input v-model="addMenuForm.menuName"
                        clearable></el-input>
            </el-form-item>

            <el-form-item label="菜单类型">
              <el-select v-model="addMenuForm.menuType"
                         placeholder="请选择菜单类型">
                <el-option :label="item.menuName"
                           :value="item.menuType"
                           v-for="(item, index) in menuTypeList"
                           :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <!-- <el-form-item label="图标">
              <el-input v-model="addMenuForm.iconfont" clearable></el-input>
            </el-form-item>-->
            <el-form-item label="状态">
              <el-select v-model="addMenuForm.status"
                         placeholder="请选择状态">
                <el-option :label="item.name"
                           :value="item.type"
                           v-for="(item, index) in status"
                           :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="请求路径"
                          prop="menuUrl">
              <el-input v-model="addMenuForm.menuUrl"
                        clearable></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="addMenuDialogVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="addMenuList()">确 定</el-button>
        </span>
      </el-dialog>
      <!--编辑菜单对话框-->
      <el-dialog :visible.sync="editMenuDialogVisible"
                 @close="editMenuDialogVisibleClose()"
                 class="edit-dialog">
        <span slot="title"
              class="dialog-Title">编辑菜单</span>
        <el-form :rules="editMenuRules"
                 ref="editMenuFormRef"
                 :model="editMenuForm"
                 inline>
          <el-row>
            <el-form-item label="上级菜单"
                          prop="parentId">
              <el-popover placement="bottom-start"
                          trigger="click"
                          class="popover-main">
                <div class="treeList">
                  <el-tree ref="tree"
                           :expand-on-click-node="false"
                           highlight-current
                           :default-expanded-keys=[editMenuForm.parentId]
                           :check-strictly="checkStrictly"
                           :data="data"
                           :props="defaultProps"
                           node-key="uuid"
                           class="permission-tree"
                           @node-click="handleNodeClick($event,'edit')" />
                </div>
                <el-input slot="reference"
                          v-model="editMenuForm.parentIdName"
                          placeholder="请选择上级菜单"
                          readonly />
              </el-popover>
              <!-- <el-select v-model="editMenuForm.parentId"
                         placeholder="请选择上级菜单"
                         clearable>
                <el-option :label="item.menuName"
                           :value="item.uuid"
                           v-for="(item, index) in allData"
                           :key="index"></el-option>
              </el-select> -->
            </el-form-item>
            <el-form-item label="菜单名称"
                          prop="menuName">
              <el-input v-model="editMenuForm.menuName"
                        clearable></el-input>
            </el-form-item>
            <el-form-item label="菜单类型">
              <el-select v-model="editMenuForm.menuType"
                         placeholder="请选择菜单类型">
                <el-option :label="item.menuName"
                           :value="item.menuType"
                           v-for="(item, index) in menuTypeList"
                           :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-row>
          <el-row>
            <!-- <el-form-item label="图标">
              <el-input v-model="editMenuForm.iconfont" clearable></el-input>
            </el-form-item>-->
            <el-form-item label="状态">
              <el-select v-model="editMenuForm.status"
                         placeholder="请选择状态">
                <el-option :label="item.name"
                           :value="item.type"
                           v-for="(item, index) in status"
                           :key="index"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="请求路径"
                          prop="menuUrl">
              <el-input v-model="editMenuForm.menuUrl"
                        clearable></el-input>
            </el-form-item>
          </el-row>
        </el-form>
        <span slot="footer"
              class="dialog-footer">
          <el-button @click="editMenuDialogVisible = false">取 消</el-button>
          <el-button type="primary"
                     @click="editUser()">确 定</el-button>
        </span>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'menuManage',
  components: {
  },
  data () {
    return {
      showName: '',
      showAddID: '',
      checkStrictly: true,
      data: [],
      allData: [],
      defaultProps: {
        children: 'twoMenusList',
        label: 'menuName'
      },
      options: { children: 'twoMenusList', hasChildren: 'hasChildren' },
      addMenuDialogVisible: false,
      editMenuDialogVisible: false,
      addMenuForm: {
        parentIdName: '',
        parentId: '', // 父级菜单id
        menuName: '', // 菜单名称
        createBy: 1, // 用户ID
        status: '1', // 状态  1是正常 2是停用
        menuType: '0', // 菜单类型(0：菜单；1：按钮）
        menuUrl: '', // 请求路径
        iconfont: '', // 图标
        applicationCode: '10000'
      }, // 添加菜单
      addMenuRules: {
        // topMenu:[
        //   {required: true ,message : '请输入上级菜单', trigger:'blur'}
        // ],

        menuName: [
          { required: true, message: '请输入菜单名称', trigger: 'blur' }
        ],
        menuUrl: [
          { required: true, message: '请输入请求路径', trigger: 'blur' }
        ]
      }, // 添加菜单校验规则
      menuTypeList: [
        { menuName: '菜单', menuType: '0' },
        { menuName: '按钮', menuType: '1' }
      ], // 菜单类型
      status: [
        { name: '正常', type: '1' },
        { name: '停用', type: '2' }
      ],
      selectMenu: { MENU_NAME: '' }, // 选择的菜单
      editMenuForm: {
        parentIdName: '',
        parentId: 0, // 父级菜单id
        menuName: '', // 菜单名称
        createBy: 1, // 用户ID
        status: '1', // 状态  1是正常 2是停用
        menuType: '0', // 菜单类型(0：菜单；1：按钮）
        iconfont: '',
        menuUrl: '',
        applicationCode: '10000'
      }, // 编辑菜单
      editMenuRules: {
        parentId: [
          { required: true, message: '请选择上级菜单', trigger: 'blur' }
        ],
        menuName: [
          { required: true, message: '请输入菜单名称', trigger: 'blur' }
        ],
        menuUrl: [
          { required: true, message: '请输入请求路径', trigger: 'blur' }
        ]
      }, // 编辑菜单校验规则
      delForm: {
        menuName: ''
      },
      btnList: sessionStorage.getItem('btnList')
    }
  },
  created () {
    // this.allM(JSON.parse(sessionStorage.getItem('menuList')).twoMenusList)
    this.getMenuList()
  },
  methods: {
    handleNodeClick (data, type) {
      if (type === 'add') {
        this.addMenuForm.parentId = data.uuid
        this.addMenuForm.parentIdName = data.menuName
      } else {
        this.editMenuForm.parentId = data.uuid
        this.editMenuForm.parentIdName = data.menuName
        this.$forceUpdate()
      }

      document.body.click()
    },
    // 给表格头部设定背景色
    rowClass ({ row, rowIndex }) {
      return 'background:#e4eaec'
    },
    // 切换选中表格项时触发
    handleCurrentChange (val) {
      if (!val) return
      this.editMenuForm = _.cloneDeep(val)
      this.delForm = _.cloneDeep(val)
      this.showName = this.allData.find(item => {
        return item.uuid == this.editMenuForm.parentId
      }).menuName
      this.showAddID = this.editMenuForm.parentId
    },
    // 获取所有菜单
    async getMenuList () {
      const { data: res } = await this.$http.post('/userManageServer/user/selectAllMenuList', {
        applicationCode: '10000'
      })
      // 判断请求是否成功
      if (res.statusCode !== '200') return this.$message.error('获取菜单列表失败')
      // res.data.forEach(item => {
      //   item.children = _.cloneDeep(item.twoMenusList)
      // })
      this.data = res.data
      this.allData = []
      res.data.forEach(item => {
        var obj = {}
        obj.menuName = item.menuName
        obj.uuid = item.uuid
        this.allData.push(obj)
        item.twoMenusList.forEach(i => {
          var ob = {}
          ob.menuName = i.menuName
          ob.uuid = i.uuid
          this.allData.push(ob)
          i.twoMenusList.forEach(k => {
            var o = {}
            o.menuName = k.menuName
            o.uuid = k.uuid
            this.allData.push(o)
          })
        })
      })
      var b = {}
      b.menuName = '无'
      b.uuid = 0
      this.allData.push(b)
      //   console.log(this.data, 'this.data ')
      //   console.log(this.allData, 'this.allData')
    },
    // 遍历递归出页面按钮
    allM (v) {
      // console.log(v);
      v.forEach(item => {
        if (item.twoMenusList && item.twoMenusList.length > 0) {
          return this.allM(item.twoMenusList)
        } else {
          console.log(item)
        }
      })
    },
    // 添加菜单--确定按钮
    async addMenuList () {
      // 预校验
      this.$refs.addMenuFormRef.validate(async valid => {
        if (!valid) return
        if (this.addMenuForm.parentId === '') {
          this.addMenuForm.parentId = 0
        }
        // console.log(JSON.stringify(this.addMenuForm))
        // 调接口，发送添加菜单请求
        const { data: res } = await this.$http.post('/userManageServer/user/addMenu', JSON.parse(JSON.stringify(this.addMenuForm)))
        console.log(res)
        // 判断请求是否成功
        if (res.statusCode !== '200') return this.$message.error('添加失败')
        // 成功提示
        this.$message.success('添加成功')
        // 重新渲染数据
        this.getMenuList()
        // 关闭弹框
        this.addMenuDialogVisible = false
      })
    },
    addBtn () {
      this.addMenuForm.parentIdName = this.showName
      this.addMenuForm.parentId = this.showAddID
      this.addMenuDialogVisible = true
    },
    // 修改按钮
    eidtMenu () {
      this.editMenuForm.parentIdName = this.showName
      // 判断是否选择编辑对象
      if (this.editMenuForm.menuName === '') return this.$message.error('请先选择编辑对象')
      // 显示编辑弹出框
      this.editMenuDialogVisible = true
      this.$forceUpdate()
    },
    // 编辑菜单--确定按钮
    editUser () {
      // console.log(JSON.stringify(this.editMenuForm));
      // 预校验
      this.$refs.editMenuFormRef.validate(async valid => {
        if (!valid) return
        // 调接口，发送编辑客户信息请求
        const { data } = await this.$http.post('/userManageServer/user/updateMenuInfo', JSON.parse(JSON.stringify(this.editMenuForm)))
        // 判断请求是否成功
        if (data.statusCode !== '200') return this.$message.error('编辑菜单失败')
        // 成功提示
        this.$message.success('编辑菜单成功')
        // 重新渲染数据
        this.getMenuList()
        // 关闭弹框
        this.editMenuDialogVisible = false
      })
    },
    // 删除按钮
    delMenu () {
      // 判断是否选择编删除对象
      if (this.delForm.menuName === '') return this.$message.error('请先选择删除对象')
      // 显示删除确认框
      this.open(this.delForm)
    },
    open (e) {
      this.$confirm(`是否确认删除：${e.menuName}`, '删除菜单', {
        confirmButtonText: '确定删除',
        cancelButtonText: '取消删除',
        type: 'warning'
      }).then(async () => {
        // console.log(e.uuid);
        // 发请求，删除用户
        try {
          const { data } = await this.$http.post('/userManageServer/user/deleteMenu', JSON.parse(JSON.stringify(e)))
          // 判断是否删除成功
          if (data.statusCode !== '200') return this.$message.error('删除菜单失败')
          this.delForm = { menuName: '' }
          // 成功提示
          this.$message.success('删除菜单成功')
          // 刷新视图
          this.getMenuList()
        } catch (e) {
          // console.log(e)
        }
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消删除'
        })
      })
    },
    // 监听添加菜单对话框的关闭事件
    addMenuDialogVisibleClose () {
      this.$refs.addMenuFormRef.resetFields()
      this.addMenuForm = {
        parentId: '', // 父级菜单id
        menuName: '', // 菜单名称
        createBy: 1, // 用户ID
        status: '1', // 状态  1是正常 2是停用
        menuType: '0', // 菜单类型(0：菜单；1：按钮）
        applicationCode: '10000'
      }
    },
    // 监听修改菜单对话框的关闭事件
    editMenuDialogVisibleClose () {
      this.$refs.editMenuFormRef.resetFields()
      // this.editMenuForm = {
      //   parentId: 0, // 父级菜单id
      //   menuName: '', // 菜单名称
      //   createBy: 1, // 用户ID
      //   status: '1', // 状态  1是正常 2是停用
      //   menuType: '0', // 菜单类型(0：菜单；1：按钮）
      //   applicationCode: '10000'
      // }
    }
  }
}
</script>
<style lang="less" scoped>
.menuManage {
  .btns {
    margin-bottom: 20px;
  }
  .treeData {
    /deep/ .treeTable {
      // margin-bottom: 20px;
      .el-table__row--striped td {
        background-color: #e4eaec !important;
      }
      .current-row > td {
        background-color: #66b1ff !important;
      }
    }
  }
  .edit-dialog,
  .add-dialog {
    .dialog-Title {
      color: #0097fe;
      font-size: 20px;
      font-weight: 700;
    }
    /deep/ .el-form {
      .subtitle {
        color: #0097fe;
        font-size: 14px;
        font-weight: 700;
        margin-bottom: 20px;
        padding-left: 6px;
        border-left: 5px solid #0097fe;
      }
      .el-row {
        display: flex;
        .el-form-item {
          flex: 1;
          display: flex;
          .el-form-item__content {
            flex: 1;
            .el-input {
              width: 100%;
              .el-input__inner {
                width: 100%;
              }
            }
            .el-select {
              width: 100%;
            }
          }
        }
      }
    }
  }
}

.popover-main {
  ::v-deep .el-input__prefix {
    display: flex;
    align-items: center;
  }
}

.treeList {
  width: 350px;
  //   flex: 1;
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>
